import React, { useContext } from 'react'
import { MenuContext } from '../../context/MenuContext'
import useIntersect from '../../hooks/useIntersect'
import { Button } from 'components/Button'

const SectionContent = React.memo(
  ({ content, h1Tag, ctaButton, colorTheme, nextSection }) => {
    const { menuOpen } = useContext(MenuContext)
    const { header, subheader, body } = content
    const [intersectionRef, entry] = useIntersect({})

    return (
      <div
        className={`iliad__section--content-animation ${
          entry.isIntersecting && !menuOpen ? 'animation-section' : ''
        }`}
        ref={intersectionRef}>
        {h1Tag ? (
          <h1 dangerouslySetInnerHTML={{ __html: header }} />
        ) : (
          <h2 dangerouslySetInnerHTML={{ __html: header }} />
        )}
        {subheader && (
          <p
            className='iliad__type--subheader'
            dangerouslySetInnerHTML={{ __html: subheader }}
          />
        )}
        {body && (
          <div
            className='iliad__section--body'
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
        {ctaButton.buttonText && (
          <Button
            buttonText={ctaButton.buttonText}
            buttonURL={ctaButton.buttonURL}
            buttonAction={ctaButton.buttonAction}
            onClick={ctaButton.onClick}
            colorTheme={colorTheme}
            nextSection={nextSection}
          />
        )}
      </div>
    )
  }
)

export default SectionContent
